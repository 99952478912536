import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import Globals from "../global/Globals";
import {
  CarCleaningService,
  textStyle,
} from "../screens/home.styles";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Common from "../screens/Common";

const CarCleaningServicePlan = () => {
  const styleSheet = textStyle();

  const locationData = useLocation();
  const location = locationData.state;

  const { carBrand, carModel } = location;

  const BASE_URL = Globals.BASE_URL;
  const classes = CarCleaningService();
  const [carServicePlanTitle, setCarServicePlanTitle] = useState([]);
  const [carServicePlanData, setCarServicePlanData] = useState([]);
  const [carType, setCarType] = useState(null);
  const [subscriptionDurationData, setSubscriptionDurationData] = useState([]);
  const [subscriptionDuration, setSubscriptionDuration] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(2);
  const [selectVisit, setSelectVisit] = useState("");
  const [visitsList, setVisitsList] = useState([]);

  const setPlan = (num) => {
    setSelectedPlan(num);
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-getCarType.html?car_model_id=${carModel}`,
    };
    axios(config)
      .then((response) => {
        setCarType(response.data.car_type_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [carModel]);

  useEffect(() => {
    getCarServiceData();
    getSubscriptionDuration();
  }, []);
  const getCarServiceData = async () => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-carServiceDataList.html?car_brand_id=${carBrand}&car_type_id=${carType}&car_model_id=${carModel}`,
    };
    axios(config)
      .then((response) => {
        // console.log("log---->", response.data);
        // setLoading(false);
        setCarServicePlanTitle(response.data.plans);
        setCarServicePlanData(response.data.data);

        let visitArray = response.data.visit_type;
        let allVisitList = visitArray.map(function (ele) {
          return { ...ele, select: false };
        });
        const updatedList = allVisitList.map((item) => {
          return item.id == 3
            ? { ...item, select: true }
            : { ...item, select: false };
        });
        setVisitsList(updatedList);
        let selection = updatedList.filter((item) => {
          return item.select == true;
        });
        let arr = selection.map((item) => item.title);
        setSelectVisit(...selection);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSubscriptionDuration = async () => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-subscriptionDuration.html`,
    };

    axios(config)
      .then((response) => {
        var count = Object.keys(response.data.data).length;
        let subscriptionDurationArray = [];
        for (var i = 0; i < count; i++) {
          subscriptionDurationArray.push({
            value: response.data.data[i].id,
            label: response.data.data[i].title,
          });
        }
        setSubscriptionDurationData(subscriptionDurationArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const planIcon = (data) => {
    if (data == "Yes") {
      return (
        <img
          // resizeMode="center"
          style={{ width: 15, height: 15, marginLeft: 10 }}
          src={require("../assets/images/check.png")}
        />
      );
    } else {
      return (
        <img
          // resizeMode="center"
          style={{ width: 15, height: 15, marginLeft: 10 }}
          src={require("../assets/images/cross.png")}
        />
      );
    }
  };
  const visitPlaneSelect = (id) => {
    const updatedList = visitsList.map((item) => {
      return item.id === id
        ? { ...item, select: true }
        : { ...item, select: false };
    });
    setVisitsList(updatedList);
    let selection = updatedList.filter((item) => {
      return item.select == true;
    });
    let arr = selection.map((item) => item.title);
    setSelectVisit(...selection);
  };

  console.log(selectVisit);
  return (
    <React.Fragment>
      <Common>
        <Container>
          <Grid
            container
            columns={12}
            style={{
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              padding: "0.5rem",
              marginTop: "3rem",
            }}
            sx={{ bgcolor: "#095659" }}
          >
            <Grid
              item
              xs={6}
              sx={{ color: "#FFF", textAlign: "start", fontWeight: "600" }}
            >
              Visits
            </Grid>
          </Grid>
          <Box
            sx={{
              shadowOpacity: 0.5,
              shadowRadius: 2,
              boxShadow: 2,
              borderBottomRightRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              backgroundColor: "#FDFDFD",
              padding: "1.5rem",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <div></div>
              <div></div>
              <div
                style={{
                  fontSize: 16,
                  textShadow: "1px 1px 1px #000",
                  color: "#000",
                }}
              >
                Extarior
              </div>
              <div
                style={{
                  fontSize: 16,
                  textShadow: "1px 1px 1px #000",
                  color: "#000",
                }}
              >
                Interior
              </div>
              <div></div>
            </Box>
            {visitsList.map((item) => {
              return (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <div
                    style={{
                      width: "25%",
                      textAlign: "start",
                      textShadow: "1px 1px 1px #000",
                    }}
                  >
                    {item.title}
                  </div>
                  <div style={{ width: "30%", textAlign: "center" }}>
                    {item.external_visits}
                  </div>
                  <div style={{ width: "23%", textAlign: "left" }}>
                    {item.internal_visits}
                  </div>
                  <FormControlLabel
                    value=""
                    control={<Radio size="small" />}
                    checked={item.select}
                    onClick={() => visitPlaneSelect(item.id)}
                    sx={{ margin: 0, padding: 0 }}
                  />
                </Box>
              );
            })}
          </Box>

          <Grid
            container
            columns={12}
            style={{
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              padding: "0.5rem",
              marginTop: "3rem",
            }}
            sx={{ backgroundColor: "#095659" }}
          >
            <Grid
              item
              xs={6}
              sx={{ color: "#FFF", textAlign: "start", fontWeight: "600" }}
            >
              Maintenance
            </Grid>
            {carServicePlanTitle.map((item) => (
              <>
                <Grid
                  item
                  xs={2}
                  sx={{ color: "#FFF", textAlign: "start", fontWeight: "400" }}
                >
                  {item.title}
                </Grid>
              </>
            ))}
          </Grid>
          <Box
            sx={{
              shadowOpacity: 0.5,
              shadowRadius: 2,
              boxShadow: 2,
              borderBottomRightRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              backgroundColor: "#FDFDFD",
              padding: "1.5rem",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Grid container columns={12} style={{ background: "#FFF" }}>
              <Grid item xs={6}></Grid>
              {carServicePlanTitle.map((item) => (
                <>
                  <Grid
                    item
                    xs={2}
                    // sx={{ color: "#000", textAlign: "start", fontSize: "2vw" }}
                    className={styleSheet.fontSizeCss}
                  >
                    ₹{item.price}
                  </Grid>
                </>
              ))}
            </Grid>

            <Grid
              container
              columns={12}
              direction={"column"}
              style={{ background: "#FFF", marginTop: "1rem" }}
            >
              {carServicePlanData.map((item) => (
                <Box sx={{ display: "flex" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      color: "#000",
                      textAlign: "start",
                      marginTop: "0.7rem",
                      width: "30%",
                    }}
                  >
                    {item.title}
                  </Grid>
                  {item.plan.map((item, index) => (
                    <Grid
                      item
                      xs={2}
                      sx={{
                        //   color: "#000",
                        display: "flex",
                        // marginRight:6,
                        // width:'30%'
                      }}
                    >
                      {planIcon(item.value)}
                    </Grid>
                  ))}
                </Box>
              ))}
            </Grid>

            <Grid
              container
              columns={12}
              style={{ background: "#FFF", marginTop: "1rem" }}
            >
              <Grid item xs={6} sx={{ textAlign: "start", fontWeight: "700" }}>
                Select Plan
              </Grid>

              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  checked={selectedPlan === 1}
                  onClick={() => setPlan(1)}
                  sx={{ margin: 0, padding: 0 }}
                />
              </Grid>

              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  checked={selectedPlan === 2}
                  onClick={() => setPlan(2)}
                  sx={{ margin: 0, padding: 0 }}
                />
              </Grid>

              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  checked={selectedPlan === 3}
                  onClick={() => setPlan(3)}
                  sx={{ margin: 0, padding: 0 }}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid direction={"row"}>
            <Grid>
              <p
                style={{
                  fontSize: 13,
                  fontWeight: "700",
                  paddingTop: 10,
                  paddingHorizontal: 10,
                  color: "#000000",
                  margin: 0,
                }}
              >
                Add Two-Wheeler
              </p>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "700",
                  marginLeft: 8,
                  color: "#328589",
                  margin: 0,
                }}
              >
                + 300 {"\n"}
                <span style={{ color: "#000", margin: 0, fontSize: 12 }}>
                  (Per Vehicle)
                </span>
              </p>
            </Grid>
            {/* <Grid>
                  <p>hello</p>
                  </Grid> */}
          </Grid>

          <Grid
            container
            columns={12}
            style={{
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              padding: "0.5rem",
              marginTop: "3rem",
            }}
            sx={{ backgroundColor: "#095659" }}
          >
            <Grid
              item
              // xs={6}
              sx={{ color: "#FFF", textAlign: "start", fontWeight: "600" }}
            >
              Subscription Period
            </Grid>
          </Grid>
          <Box
            sx={{
              shadowOpacity: 0.5,
              shadowRadius: 2,
              boxShadow: 2,
              borderBottomRightRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              backgroundColor: "#FDFDFD",
              padding: "1.5rem",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 16, fontWeight: "bolder" }}>
              {" "}
              Select the duration of your subscription
            </span>
            <FormControl  className={classes.dropdown}>
              <InputLabel id="demo-simple-select-label">
                Select Duration
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subscriptionDuration}
                label="Select Duration"
                onChange={(e) => setSubscriptionDuration(e.target.value)}
                displayEmpty={false}
              >
                {subscriptionDurationData.map((item) => (
                  <MenuItem value={item.value} style={{ textAlign: "center" }}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Container>
        <Button
          variant="contained"
          style={{
            color: "#fff",
            backgroundColor: "#328589",
            marginTop: "2rem",
            textTransform: "capitalize",
            padding: "0.5rem 2rem",
          }}
          href="https://play.google.com/store/apps/details?id=com.doable&hl=en_IN&gl=US"
          target="_blank"
        >
          Confirm
        </Button>
      </Common>
    </React.Fragment>
  );
};
export default CarCleaningServicePlan;
