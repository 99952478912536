import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Locastion from "@mui/icons-material/LocationOn";
import { CardContent, Icon, MenuItem, Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import car from "../assets/images/icon/car.png";
import cook from "../assets/images/icon/cook.png";
import laundry from "../assets/images/icon/Must-Maintenance.png";
import MyRunner from "../assets/images/icon/my_runner.png";
import Daliy from "../assets/images/icon/daily-cleaner.png";
import MustMaintence from "../assets/images/icon/Must-Maintenance_gray.png";
import Pancture from "../assets/images/icon/puncture_gray.png";
import Water from "../assets/images/icon/Water_Supply_icon.png";
import Alteration from "../assets/images/icon/Alteration_icon.png";
import { useStyles } from "./home.styles";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { CssTransition } from "@mui/base/Transitions";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Globals from "../global/Globals";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./styles.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Common from "./Common";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Arrow from "@mui/icons-material/ArrowForward";
import Slide from "@mui/material/Slide";
import YouTube from "react-youtube";

const Home = (props) => {
  const BASE_URL = Globals.BASE_URL;
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useStyles();
  const [sectorData, setSectorData] = React.useState([]);
  const [selectSector, setSelectSector] = React.useState();
  const [societyData, setSocietyData] = React.useState([]);
  const [society, setSociety] = React.useState([]);
  const [slider1Data, setSlider1Data] = useState([]);
  const [slider2Data, setSlider2Data] = useState([]);
  const [location, setLocation] = useState();
  const [videoData, setVideoData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-slider.html?page_title=Home&slider_position=Position 1`,
    };

    axios(config)
      .then((response) => {
        if (response.data.data.length) {
          let images = response.data.data.map((item) => {
            return item.image;
          });
          setSlider1Data(images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-slider.html?page_title=Home&slider_position=Position 3`,
    };

    axios(config)
      .then((response) => {
        if (response.data.data.length) {
          let images = response.data.data.map((item) => {
            return item.image;
          });
          // setSlider1Data(images);
          setSlider2Data(images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    // checkToken();
    // sessionConfig.authConfig();
    var config = {
      method: "get",
      url: `${BASE_URL}/api-getSector.html`,
    };

    axios(config)
      .then((response) => {
        let count = Object.keys(response.data.data).length;
        let sectorArray = [];
        for (let i = 0; i < count; i++) {
          sectorArray.push({
            value: response.data.data[i].title,
            label: response.data.data[i].title,
          });
        }
        setSectorData(sectorArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handlesociety = (sectorCode) => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-getSociety.html?sector=${sectorCode}`,
    };

    axios(config)
      .then(function (response) {
        var count = Object.keys(response.data.data).length;
        let societyArray = [];
        for (var i = 0; i < count; i++) {
          societyArray.push({
            value: response.data.data[i].title,
            label: response.data.data[i].title,
          });
        }
        setSocietyData(societyArray);
        console.log("event-------->", societyArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSector = (event) => {
    setSelectSector(event.target.value);
  };

  const AnimatedListbox = React.forwardRef(function AnimatedListbox(
    props,
    ref
  ) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
      );
    }

    const verticalPlacement = popupContext.placement.split("-")[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  });

  AnimatedListbox.propTypes = {
    ownerState: PropTypes.object.isRequired,
  };
  const Listbox = styled("ul")(
    ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 6px;
        margin: 12px 0;
        min-width: 200px;
        border-radius: 12px;
        overflow: auto;
        outline: 0px;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        box-shadow: 0px 4px 30px ${
          theme.palette.mode === "dark" ? grey[900] : grey[200]
        };
        z-index: 1;
      
        .closed & {
          opacity: 0;
          transform: scale(0.95, 0.8);
          transition: opacity 200ms ease-in, transform 200ms ease-in;
        }
        
        .open & {
          opacity: 1;
          transform: scale(1, 1);
          transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
        }
      
        .placement-top & {
          transform-origin: bottom;
        }
        &:hover {
            background: ${theme.palette.mode ? grey[800] : grey[50]};
            border-color: ${theme.palette.mode ? grey[600] : grey[300]};
          }
    
      
        .placement-bottom & {
          transform-origin: top;
        }
        `
  );

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const onLocationSubmitted = () => {
    if (selectSector === undefined && society.length === 0) {
      toast.error("Please select Society and Location");
    } else if (selectSector === undefined) {
      toast.error("Please select Society");
    } else if (society.length === 0) {
      toast.error("Please select location");
    } else {
      console.log("onLocationSubmitted calllng");
      setOpenModal(false);
      navigate("CarCleaning");
    }
  };

  const alertText = (text, img) => {
    return toast(`${text} Coming Soon!`, {
      icon: <img src={img} style={{ height: "2rem", width: "2rem" }}></img>,
    });
  };

  useEffect(() => {
    getCurrentLocation();
    getAllVideo();
  }, []);
  const getCurrentLocation = () => {
    const myApiKey = "AIzaSyDJ6wzetFNL8_X2jddyKYJRRyLIjvrZSkI";
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("position", position);
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "&key=" +
            myApiKey
        )
        .then((responseJson) => {
          if (responseJson.status == "200") {
            setLocation(responseJson?.data.results[4]?.formatted_address);
          } else {
            console.log("not found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  // const getAllVideo = () => {
  //   // let url = `${BASE_URL}/api-getGalleryData.html`;

  //   var config = {
  //     method: "POST",
  //     url: `${BASE_URL}/api-getGalleryData.html`,
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   axios.post(BASE_URL+'/api-getGalleryData.html',{main_category_id:"1",gallery_type:"Video"},{
  //     headers : {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //     }
  //   }).then((response) => {
  //     console.log("video---->", response);
  //   });

  //   // fetch(url, {
  //   //   method: "POST",
  //   //   headers: {
  //   //     Accept: "application/json",
  //   //     "Content-Type": "application/json",
  //   //   },
  //   //   body: JSON.stringify({ main_category_id: "1", gallery_type: "Video" }),
  //   // })
  //   //   .then((response) => response.json())
  //   //   .then((responseData) => {
  //   //     console.log("video ----->", responseData.data);
  //   //     setVideoData(responseData.data);
  //   //   });
  // };

  const getAllVideo = () => {
    console.log("video---->");
    let url = `${Globals.BASE_URL}/api-getGalleryData.html`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(sendingData)
      body: JSON.stringify({
        main_category_id: "1",
        gallery_type: "Video",
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("video ----->", responseData.data);
        setVideoData(responseData.data);
      })
      .catch((error) => {
        console.log("errorr------->", error);
      });
  };

  return (
    <React.Fragment>
      <Box sx={{}}>
        <Common>
          <Container style={{ padding: "0 0.5rem", marginTop: "2rem" }}>
            <Box
              container
              style={{ alignItems: "center" }}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Grid style={{ marginRight: 4 }}>
                <IconButton size="large" edge="start" color="inherit">
                  <Locastion />
                </IconButton>
              </Grid>
              <Grid>
                <span
                  style={{
                    fontSize: 16,
                    color: "#000",
                    alignSelf: "flex-start",
                    // paddingLeft: "0.4rem",
                    margin: 0,
                  }}
                >
                  {location}
                </span>
              </Grid>
            </Box>

            <Swiper
              style={{ minHeight: "300px" }}
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              {slider1Data.map((item) => (
                <SwiperSlide
                  style={{
                    alignItems: "center",
                    background: "#fff",
                    height: "50%",
                  }}
                >
                  <img
                    src={item}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "38%",
                      borderRadius: "0.6rem",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <h2 style={{ color: "#000", textAlign: "start" }}>Categories</h2> */}
            <Grid
              sx={{
                bgcolor: "#fff",
                width: "100%",
                marginBottom: "6rem",
                placeItems: "center",
                placeContent: "center",
                // marginTop: "20px",
                paddingTop: "35px",
              }}
              columns={10}
              container
              rowSpacing={{ xs: 6, sm: 6, md: 5, lg: 12 }}
              columnSpacing={{ xs: 3, sm: 4, md: 5, lg: 2 }}
            >
              <Grid item lg={2} md={2} sm={5}>
                <CardContent
                  style={{ bgcolor: "red", cursor: "pointer" }}
                  className={[classes.cardStyle, "card-hover"]}
                  onClick={() => setOpenModal(true)}
                >
                  <img src={car} className={classes.imageCss} />
                  <p className={classes.cardText}>Car Cleaning</p>
                </CardContent>
              </Grid>

              <Grid item md={2} sm={5}>
                <CardContent
                  style={{ bgcolor: "red" }}
                  className={[classes.cardStyle, "card-hover"]}
                  onClick={() => {
                    alertText("Necessary Care", MustMaintence);
                  }}
                >
                  <img src={MustMaintence} className={classes.imageCss} />
                  <p className={classes.cardText}>Necessary Care</p>
                </CardContent>
              </Grid>

              <Grid item md={2} sm={5}>
                <CardContent
                  style={{ bgcolor: "red" }}
                  className={[classes.cardStyle, "card-hover"]}
                  onClick={() => {
                    alertText("Pancture", Pancture);
                  }}
                >
                  <img src={Pancture} className={classes.imageCss} />
                  <p className={classes.cardText}>Pancture</p>
                </CardContent>
              </Grid>

              <Grid item md={2} sm={5}>
                <CardContent
                  style={{ bgcolor: "red" }}
                  className={[classes.cardStyle, "card-hover"]}
                  onClick={() => {
                    alertText("Water", Water);
                  }}
                >
                  <img src={Water} className={classes.imageCss} />
                  <p className={classes.cardText}>H2O</p>
                </CardContent>
              </Grid>

              <Grid item md={2} sm={5}>
                <CardContent
                  style={{ bgcolor: "red" }}
                  className={[classes.cardStyle, "card-hover"]}
                  onClick={() => {
                    alertText("Alteration", Alteration);
                  }}
                >
                  <img src={Alteration} className={classes.imageCss} />
                  <p className={classes.cardText}>Alteration</p>
                </CardContent>
              </Grid>
              <Toaster
                toastOptions={{
                  style: {
                    border: "1px solid #000",
                  },
                  className: "toast-width",
                }}
              />
            </Grid>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              {slider2Data.map((item) => (
                <SwiperSlide
                  style={{
                    alignItems: "center",
                    fontSize: "18px",
                    background: "#fff",
                    height: "50%",
                  }}
                >
                  <img
                    src={item}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "50%",
                      borderRadius: "0.6rem",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              {videoData.map((item) => {
                const videoId = getYouTubeVideoId(item.path);
                function getYouTubeVideoId(url) {
                  var regExp =
                    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                  var match = url.match(regExp);
                  return match ? match[1] : null;
                }

                <YouTube
                  videoId={videoId}
                  opts={opts}
                  onReady={onPlayerReady}
                  style={{ width: "90%", padding: "30px 0px" }}
                />;
              })}
            </Swiper>

            <p
              style={{
                color: "#328589",
                fontSize: 22,
                fontWeight: "bolder",
                paddingTop: "1rem",
                textAlign: "start",
                margin: 0,
              }}
            >
              Car Care That Goes Beyond Clean
            </p>
            <p
              style={{
                margin: 0,
                textAlign: "justify",
                marginBottom: "2rem",
                fontSize: 16,
                // fontFamily: "Poppins-Light",
              }}
            >
              Daily car cleaning isn't just another chore to check off your list
              – it's a{" "}
              <span className={classes.homePageText}>
                Passionate Commitment
              </span>{" "}
              to safeguarding your valuable vehicle. At Doable daily car care,
              we don't just clean cars like average car cleaners using a bucket
              and cloth; we pamper your car with the{" "}
              <span className={classes.homePageText}>finest</span> tools and
              techniques, ensuring they look their absolute best, maintaining
              that head-turning{" "}
              <span className={classes.homePageText}>Beauty & Value</span> for
              years to come. So, what are you waiting for? Let's embark on this
              journey together and embrace a{" "}
              <span className={classes.homePageText}>Lifestyle</span> that
              prioritizes your car looking best in all possible conditions.
              Because at Doable Daily Car Care, we're not just cleaning cars –
              we're{" "}
              <span className={classes.homePageText}>Preserving Legacies</span>
               on wheels.
            </p>
            <h3
              style={{
                color: "#328589",
                fontSize: 22,
                fontWeight: "bolder",
                marginTop: "2rem",
                textAlign: "start",
                marginBottom: 0,
              }}
            >
              Safeguard Your Car's Value
            </h3>
            <p
              style={{
                margin: 0,
                textAlign: "justify",
                fontSize: 16,
              }}
            >
              Daily Car cleaning of your car is{" "}
              <span className={classes.homePageRedText}>Crucial</span> for its
              appearance and longevity, but using a{" "}
              <span className={classes.homePageRedText}>‘Balti’</span> and a{" "}
              <span className={classes.homePageRedText}>‘Kapda'</span> can lead
              to unintended consequences. These include{" "}
              <span className={classes.homePageRedText}>Spider Scratches</span>,
              lack of shine,{" "}
              <span className={classes.homePageRedText}>Watermarks</span> on car
              surface, and dust accumulation.{" "}
              <span className={classes.homePageRedText}>
                Despite daily cleaning
              </span>
              , dust and dirt may not be completely removed, leading to a{" "}
              <span className={classes.homePageRedText}>Dull Appearance</span>{" "}
              and potential scratching. To avoid these issues,{" "}
              <span className={classes.homePageRedText}>Invest</span> in proper
              car washing tools and techniques to keep your car looking its
              absolute best.
            </p>
          </Container>
        </Common>
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          width: "100%",
          padding: "0 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slide in={openModal}>
          <Box className={classes.locationModal}>
            <IconButton
              style={{
                color: "#328589",
                marginLeft: "92%",
                marginTop: -40,
              }}
              onClick={() => setOpenModal(false)}
            >
              <HighlightOffIcon />
            </IconButton>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: "center",
                fontWeight: "600",
                marginTop: -10,
              }}
            >
              Select Your Location
            </Typography>

            <p
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {" "}
              Please select your sector & living
            </p>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="demo-simple-select-label">Select Area</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectSector}
                label="Select Area"
                onChange={(e) => {
                  handleSector(e);
                  handlesociety(e.target.value);
                  console.log("value---> ", e.target.value);
                }}
              >
                {sectorData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="demo-simple-select-label2">
                Select Living
              </InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select"
                value={society}
                label="Select Living"
                onChange={(e) => setSociety(e.target.value)}
                displayEmpty={false}
              >
                {societyData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "#328589",

                  marginTop: 15,
                  textTransform: "capitalize",
                }}
                onClick={onLocationSubmitted}
              >
                Done
                <Icon
                  color="#fff"
                  sx={{ height: 25, width: 30, marginTop: -1.5 }}
                  size="small"
                >
                  <Arrow style={{ height: 19, width: 19 }} />
                </Icon>
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </React.Fragment>
  );
};
export default Home;
