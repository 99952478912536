

import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { LOGO } from "../utility/Image.Constants";
import { Notifications, Home } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import profile from "../assets/images/sidebar-profile.png";
import inviteFrind from "../assets/images/sidebar-invite-friend.png";
import subcription from "../assets/images/sidebar-subscription.png";
import helpSupport from "../assets/images/sidebar-help-support.png";
import privacyPolicy from "../assets/images/sidebar-privacy-policy.png";
import agreement from "../assets/images/sidebar-service-policy-agreement.png";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
type Anchor = "top" | "left" | "bottom" | "right";

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const classes = useStyles();
  const toggleDrawer = (anchor: Anchor, open) => (event) => {
    console.log("toggling, event:", event);
    console.log("----TOGGLINGGGG------", event.target.innerText);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log("inspect finsih");
    setOpen(!open);
    setState({ ...state, [anchor]: open });
  };

  const listArray = [
    {
      title: "Profile Detail",
      icon: profile,
      link: "/",
    },
    {
      title: "Invite Friends",
      icon: inviteFrind,
      link: "/.",
    },
    {
      title: "Your Subscriptions",
      icon: subcription,
      link: "/.",
    },
    {
      title: "Help & Support",
      icon: helpSupport,
      link: "/HelpAndSupport",
    },
    {
      title: "Policies",
      icon: privacyPolicy,
      link: "/.",
    },
  ];

  const policyAry = [
    {
      title: "Privacy Policy",
      icon: privacyPolicy,
      link: "https://onechoiceindia.in/policy/privacy-policy.html", //Privacy_Policy_Pdf,
    },
    {
      title: "Service Policy & Agreement",
      icon: agreement,
      link: "https://onechoiceindia.in/policy/offers.html",
    },
    {
      title: "Terms And Condition",
      icon: privacyPolicy,
      link: "https://onechoiceindia.in/policy/terms-conditions.html", // Terms_And_Condition_PDF,
    },
    {
      title: "Anti Discrimination Policy",
      icon: agreement,
      link: "https://onechoiceindia.in/policy/anti-disc-policy.html",
    },
    {
      title: "Car Wash Policy",
      icon: agreement,
      link: "https://onechoiceindia.in/policy/car-terms-conditions.html",
    },
    {
      title: "Car Wash Agreement",
      icon: agreement,
      link: "/",
    },
    {
      title: "Refund And Cancellation",
      icon: agreement,
      link: "https://onechoiceindia.in/policy/refund-cancel.html",
    },
  ];
  // -------------------------------------------------
  function NestedList(anchor, toggleDrawer) {
    const [openDollar, setOpenDollar] = React.useState(true);
    console.log("inside nested list:", toggleDrawer);
    const handleClick = () => {
      setOpen(!open);
      setOpenDollar(!openDollar);
      toggleDrawer();
    };

    return (
      <div>
        <Box
          sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
          role="presentation"
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Box className={classes.toggleDrawerView}>
            <img
              src="https://cdns.iconmonstr.com/wp-content/releases/preview/2018/240/iconmonstr-user-circle-thin.png"
              className={classes.DrawerImg}
            />
            <Box>
              <div className={classes.adminText}>Admin</div>
              <div className={classes.emailText}>Admin@doable.com</div>
            </Box>
          </Box>
          <List
            className={classes.listView}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <Grid>
              {listArray.map((item, index) => {
                return (
                  <>
                    <Link
                      key={item.title}
                      to={item.link}
                      onClick={
                        item.title == "Policies"
                          ? handleClick
                          : toggleDrawer(anchor, false)
                      }
                      className={classes.linkView}
                    >
                      <img
                        src={item.icon}
                        style={{ height: "2rem", width: "2rem" }}
                      />
                      <p className={classes.titelText}>{item.title}</p>
                      {item.title == "Policies" ? (
                        openDollar ? (
                          <ChevronRightIcon
                            style={{ marginLeft: "auto" }}
                            sx={{ color: "#328589" }}
                          />
                        ) : (
                          <ExpandMore
                            style={{ marginLeft: "auto" }}
                            sx={{ color: "#328589" }}
                          />
                        )
                      ) : (
                        <ChevronRightIcon
                          style={{ marginLeft: "auto" }}
                          sx={{ color: "#328589" }}
                        />
                      )}
                    </Link>
                    <Divider />
                  </>
                );
              })}
            </Grid>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid>
                {policyAry.map((item, index) => {
                  return (
                    <>
                      <Link
                        key={item.title}
                        to={item.link}
                        onClick={
                          item.title == "Policy"
                            ? handleClick
                            : toggleDrawer(anchor, false)
                        }
                        target="blank"
                        className={classes.nested}
                      >
                        <img src={item.icon} className={classes.nestedIcon} />
                        <p className={classes.nestedText}>{item.title}</p>
                        <ChevronRightIcon
                          style={{ marginLeft: "auto" }}
                          sx={{ color: "#328589" }}
                        />
                      </Link>
                      <Divider />
                    </>
                  );
                })}
              </Grid>
            </Collapse>
          </List>
        </Box>
      </div>
    );
  }

  return (
    <div>
      {
        <React.Fragment key={"left"}>
          <Box sx={{}}>
            <AppBar
              position="static"
              sx={{ bgcolor: "#328589", height: "20h" }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer("left", true)}
                >
                  <MenuIcon />
                </IconButton>
                <Box>
                  <a href="/" style={{ color: "#fff" }}>
                    {" "}
                    <Home />
                  </a>
                </Box>
                <Box
                  flex={1}
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={LOGO}
                    style={{
                      padding: "1rem 0",
                      height: "5rem",
                    }}
                  />
                </Box>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <Notifications />
                </IconButton>
              </Toolbar>
            </AppBar>
            <CssBaseline />
          </Box>

          <SwipeableDrawer
            className={classes.container}
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {NestedList("left", toggleDrawer)}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    padding: "0 13px",
    width: "10rem",
  },
  toggleDrawerView: {
    display: "flex",
    alignItems: "center",
    margin: "1rem 0 0 0.5rem",
  },
  DrawerImg: {
    height: 50,
    width: 50,
    border: "1px solid #328589",
    borderRadius: 30,
    padding: 1,
  },
  adminText: {
    marginLeft: 10,
    color: "#328589",
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  emailText: {
    marginLeft: 10,
    fontSize: 12,
    fontWeight: "400",
    color: "#000",
    fontFamily: "Poppins",
  },
  listView: {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  },
  linkView: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    width: "100%",
    padding: "1rem 0 0 0",
    cursor: "pointer",
    textDecoration: "none",
    marginLeft: 3,
  },
  titelText: {
    marginLeft: "1rem",
    fontSize: 12,
    fontWeight: "600",
    color: "#328589",
    fontFamily: "Poppins",
  },
  nested: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    width: "100%",
    padding: "1rem 0 0 0",
    cursor: "pointer",
    textDecoration: "none",
  },
  nestedIcon: { height: "1.5rem", width: "1.5rem", marginLeft: 5 },
  nestedText: {
    marginLeft: "1rem",
    fontSize: 11,
    fontWeight: "500",
    color: "#328589",
    fontFamily: "Poppins",
  },
});
