import Car from "../assets/images/icon/car.png";
import Alterartion from "../assets/images/icon/alterartion.png";
import Cook from "../assets/images/icon/cook.png";
import DailyCleaner from "../assets/images/icon/daily-cleaner.png";
import KnifeScissorsSharpening from "../assets/images/icon/Knife-Scissors-Sharpening.png";
import Laundry from "../assets/images/icon/laundry.png";
import MustMaintenance from "../assets/images/icon/Must-Maintenance.png";
import MyRunner from "../assets/images/icon/my_runner.png";
import Puncture from "../assets/images/icon/puncture.png";
import UtensilsCleaner from "../assets/images/icon/utensils_cleaner.png";
import WaterSupply from "../assets/images/icon/water_supply.png";


export default {
  BASE_URL: true
    ? "https://onechoiceindia.in/admin"
    : "https://doablelife.com/admin",

  COLOR: {
    ORANGE: "#C50",
    DARKBLUE: "#0F3274",
    LIGHTBLUE: "#6EA8DA",
    DARKGRAY: "#999",
    PrimaryColor:'#34878B'
  },
  ServiceType: {
    Car: Car,
    Alterartion: Alterartion,
    Cook: Cook,
    DailyCleaner: DailyCleaner,
    KnifeScissorsSharpening: KnifeScissorsSharpening,
    Laundry: Laundry,
    MustMaintenance: MustMaintenance,
    MyRunner: MyRunner,
    Puncture: Puncture,
    UtensilsCleaner: UtensilsCleaner,
    WaterSupply: WaterSupply,
  },
  //googleApiKey: 'AIzaSyD_JYWy6_qw0Rq5EEOg4wW98kf_GuvKmM4',
  //googleApiKey: 'AIzaSyCpqu_61cVxNe7tHVUzmsPPL-cULpS7C_o',
  googleApiKey: "AIzaSyB_YAIaiVuXOLgE3TvFpm0hJZif5WDD520",
};
