import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import CarCleaningHome from "./car-cleaning/carCleaningHome";
import CarCleaningServicePlan from "./car-cleaning/CarCleaningServicePlan";
import HelpAndSupport, { PrivacyPolicy } from "./screens/HelpAndSupport";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CarCleaning" element={<CarCleaningHome />} />
          <Route
            path="/CarCleaningServicePlan"
            element={<CarCleaningServicePlan />}
          />
          <Route path="/HelpAndSupport" element={<HelpAndSupport />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
