import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { Box, Container, Grid, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { LOGO } from "../utility/Image.Constants";
import googlePlaBtn from "../assets/images/googleplay.png";
import { footerStyle } from "./home.styles";

const Footer = () => {
  const policyArr = [
    {
      title: "Privacy Policy",
      link: "https://onechoiceindia.in/policy/privacy-policy.html", //privacyPolicy,
    },
    {
      title: " Service Policy & Agreement",
      link: "https://onechoiceindia.in/policy/offers.html",
    },
    {
      title: "Terms And Condition",
      link: "https://onechoiceindia.in/policy/terms-conditions.html",
    },
    {
      title: "Anti Discrimination Policy",
      link: "https://onechoiceindia.in/policy/anti-disc-policy.html", //Anti_discriminationPolicy,
    },
    {
      title: "Car Wash Policy",
      link: "https://onechoiceindia.in/policy/car-terms-conditions.html",
    },
    {
      title: "Car Wash Agreement",
      link: null,
    },
    {
      title: "Refund and Cancellation",
      link: "https://onechoiceindia.in/policy/refund-cancel.html", //Refund_and_Cancellation,
    },
  ];
  const styleSheet = footerStyle();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "20%",
          backgroundColor: "#328589",
          marginTop: "2rem",
        }}
      >
        <Container sx={{}}>
          <Grid container spacing={2} columns={12}>
            <Grid item md={6}>
              <Box
                sx={{
                  justifyContent: "justify",
                  display: "flex",
                }}
              >
                <img src={LOGO} style={{ padding: "1rem 0", height: "5rem" }} />
              </Box>
              <p
                style={{
                  margin: 0,
                  textAlign: "start",
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                About us
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: "justify",
                  color: "#fff",
                  fontSize: 12,
                }}
              >
                Doable is your ultimate technology-driven solution for all daily
                needs, conveniently delivered to your doorstep. With our
                advanced platform, we ensure seamless scheduling and efficient
                service delivery. We prioritize quality and reliability by
                collaborating closely with hand-picked service partners,
                empowering them with technology, training, professional-grade
                products, and top-of-the-line tools. At Doable, we continuously
                refine our methods to exceed customer expectations, ensuring a
                hassle-free experience for both customers and our workforce. By
                organizing the unorganized, we streamline services, making them
                accessible and efficient. With Doable, it's more than just
                completing tasks – it's about simplifying lives and embracing a
                lifestyle of convenience and care.
              </p>
            </Grid>
            <Grid xs={12} sm={6} md={3} className={styleSheet.helpSupport}>
              <Grid
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <Link
                  to={"/HelpAndSupport"}
                  className={"footer_link"}
                  style={{
                    fontSize: "14",
                    color: "#fff",
                    textAlign: "start",
                    fontWeight: "600",
                    textDecoration: "none",
                    border: "1px solid #fff",
                    borderRadius: 10,
                    borderColor: "#fff",
                    padding: "0.7rem 1rem",
                  }}
                >
                  Help & Support
                </Link>
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} md={3} className={styleSheet.helpSupport}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid>
                  <Box>
                    <Box sx={{ flexDirection: "row", display: "flex" }}>
                      <IconButton
                        style={{ color: "#fff" }}
                        href="https://www.instagram.com/doablelife/"
                        target="_blank"
                      >
                        <Instagram />
                      </IconButton>

                      <IconButton
                        style={{ color: "#fff" }}
                        href="https://www.facebook.com/profile.php?id=100095088505755&mibextid=ZbWKwL"
                        target="_blank"
                      >
                        <Facebook />
                      </IconButton>
                      <IconButton
                        style={{ color: "#fff" }}
                        href="https://www.youtube.com/@Doablelife"
                        target="_blank"
                      >
                        <YouTube />
                      </IconButton>
                      <IconButton
                        style={{ color: "#fff" }}
                        href="https://twitter.com/doable_life?s=11"
                        target="_blank"
                      >
                        <Twitter />
                      </IconButton>
                    </Box>
                    <p
                      style={{
                        margin: 0,
                        color: "#fff",
                        fontSize: 14,
                        fontWeight: "500",
                        textAlign: "start",
                      }}
                    >
                      Contect Us +91-9911966226{" "}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "#fff",
                        fontSize: 14,
                        fontWeight: "500",
                        textAlign: "start",
                      }}
                    >
                      Email - info@doablelife.com
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            columns={12}
            // columnSpacing={5}
            sx={{ margin: "1.5rem 0", placeContent: "center" }}
          >
            {policyArr.map((item, index) => {
              return (
                <Grid
                  key={index}
                  md={3}
                  xs={12}
                  item
                  sx={{
                    padding: "0 0.5rem  1rem 0",
                  }}
                >
                  <a
                    href={item.link}
                    className={"footer_link"}
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#fff",
                      textAlign: "center",
                      fontWeight: "600",
                      textDecoration: "none",
                      border: "1px solid #fff",
                      borderRadius: 10,
                      borderColor: "#fff",
                      padding: "0.7rem 1rem",
                      width: "98%",
                      display: "block",
                    }}
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Grid
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                // marginTop: "0.5rem",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.doable&hl=en_IN&gl=US"
                target="_blank"
                style={{}}
              >
                <img
                  src={googlePlaBtn}
                  style={{ height: "auto", width: "8rem" }}
                />
              </a>
            </Grid>
            <Grid md={6} xs={12}>
              <p
                style={{
                  fontSize: 16,
                  color: "#fff",
                  fontWeight: "400",
                  paddingBottom: "1rem",
                  margin: 0,
                }}
              >
                Copyright @ {new Date().getFullYear()} One Choice Service Pvt
                Ltd. All right reserved
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
