import {
  Box,
  Button,
  Container,
  Icon,
  Grid,
  Modal,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Arrow from "@mui/icons-material/ArrowForward";
import car from "../assets/images/icon/car.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import Globals from "../global/Globals";
import { useStyles, CarCleaningHomeText } from "../screens/home.styles";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Common from "../screens/Common";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Slide from "@mui/material/Slide";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const CarCleaningHome = (props) => {
  
  const styleSheet = CarCleaningHomeText();
  const BASE_URL = Globals.BASE_URL;
  const classes = useStyles();
  const navigate = useNavigate();
  const [carBrandData, setCarBrandData] = useState([]);
  const [carModelData, setCarModelData] = useState([]);
  const [carBrand, setCarBrand] = useState(null);
  const [carModel, setCarModel] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [slider1Data, setSlider1Data] = useState([]);
  const [slider2Data, setSlider2Data] = useState([]);

  //Slider 1 Start ---------------
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-slider.html?page_title=Car&slider_position=Position 1`,
    };

    axios(config)
      .then((response) => {
        if (response.data.data.length) {
          let images = response.data.data.map((item) => {
            return item.image;
          });
          setSlider1Data(images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    getSlider2();
  }, []);
  const getSlider2 = () => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-slider.html?page_title=Car&slider_position=Position 2`,
    };

    axios(config)
      .then((response) => {
        if (response.data.data.length) {
          let images = response.data.data.map((item) => {
            return item.image;
          });
          setSlider2Data(images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-carBrand.html`,
    };

    axios(config)
      .then((response) => {
        var count = Object.keys(response.data.data).length;
        let carBrandArray = [];
        for (var i = 0; i < count; i++) {
          carBrandArray.push({
            value: response.data.data[i].id,
            label: response.data.data[i].title,
          });
        }
        setCarBrandData(carBrandArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCarModel = (carBrandCode) => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-carModel.html?car_brand_id=${carBrandCode}`,
    };

    axios(config)
      .then(function (response) {
        var count = Object.keys(response.data.data).length;
        let carModelArray = [];
        for (var i = 0; i < count; i++) {
          carModelArray.push({
            value: response.data.data[i].id,
            label: response.data.data[i].title,
          });
        }
        setCarModelData(carModelArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleButton = () => {
    setOpenModal(true);
  };
  const handleCarSubmit = () => {
    if (carBrand == null && carModel == null) {
      toast.error("Please select Car Brand and Car Model");
      // <Alert severity="error" color='error'>Please select Car Brand and Car Model</Alert>
    } else if (carBrand == null) {
      toast.error("Please select Car Brand");
      // <Alert severity="error">Please select Car Brand and Car Model</Alert>
    } else if (carModel == null) {
      toast.error("Please select Car Modal");
      // <Alert severity="error" >Please select Car Brand and Car Model</Alert>
    } else {
      setOpenModal(false);
      console.log("onLocationSubmitted calllng");
      const userData = { carBrand, carModel };
      navigate("/CarCleaningServicePlan", { state: userData });
    }
  };
  return (
    <React.Fragment>
      <Box sx={{}}>
        <Common>
          <Container
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ color: "#000", textAlign: "center" }}>
              Daliy Cleaning Services
            </h2>

            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              {slider1Data.map((item) => (
                <SwiperSlide
                  style={{
                    alignItems: "center",
                    fontSize: "18px",
                    background: "#fff",
                    height: "55%",
                  }}
                >
                  <img
                    src={item}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "40%",
                      borderRadius: "0.6rem",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <p
              style={{
                margin: 0,
                marginTop: "1rem",
                fontFamily: "Poppins-Light",
              }}
            >
              Check out our absolutely economic service plans specially
              taillored as per your need with our exclusive and extensive
              research
            </p>
            <Button
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "#328589",
                alignSelf: "center",
                textTransform: "capitalize",
                margin: "2rem 0",
              }}
              onClick={handleButton}
              className={"card-hover"}
            >
              View Plans
            </Button>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Swiper
                spaceBetween={30}
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                {slider2Data.map((item) => (
                  <SwiperSlide
                    style={{
                      fontSize: "18px",
                      background: "#fff",
                      height: "50%",
                    }}
                  >
                    <img
                      src={item}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "40%",
                        borderRadius: "0.6rem",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
            <Box className={styleSheet.CarCleaningHomeContainer}>
              <img
                src={require("../assets/images/potrate/1.png")}
                className={styleSheet.potrateImage}
              />
              <Box sx={{ position: "relative", bottom: 200 }}>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 20,
                    fontWeight: "revert",
                    color: "#fff",
                    textAlign: "center",
                    width: "100%",
                    alignSelf: "center",
                  }}
                >
                  Treat Yourself to the Everyday
                  <br /> Luxury of a Professionally
                  <br /> Cleaned Car
                </p>
              </Box>
              <img
                src={require("../assets/images/letsWantToNeed.png")}
                className={styleSheet.letWantImage}
              />
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    src={require("../assets/images/iconss/1.png")}
                    className={styleSheet.icons}
                  />
                  <div className={styleSheet.iconText}>
                    Expert Care Delivered At Home.
                  </div>
                </Box>
                <Box>
                  <img
                    src={require("../assets/images/iconss/2.png")}
                    className={styleSheet.icons}
                  />
                  <div className={styleSheet.iconText}>
                    Professionally Trained Partners.
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <Box>
                  <img
                    src={require("../assets/images/iconss/3.png")}
                    className={styleSheet.icons}
                  />
                  <div className={styleSheet.iconText}>
                    Premium-Grade Products Used.
                  </div>
                </Box>
                <Box>
                  <img
                    src={require("../assets/images/iconss/4.png")}
                    className={styleSheet.icons}
                  />
                  <div className={styleSheet.iconText}>
                    Eco-Friendly Tools & Techniques.
                  </div>
                </Box>
              </Box>
              <Box sx={{ marginTop: "3rem" }}>
                <img
                  src={require("../assets/images/iconss/5.png")}
                  className={styleSheet.icons}
                />
                <div className={styleSheet.iconText}>
                  Daily Service for a Clean Ride Everyday.
                </div>
              </Box>
              <div className={styleSheet.textCss}>WHAT WE CAN DO FOR YOU</div>
              <div
                style={{
                  color: "#41B4bb",
                  fontSize: 15,
                  fontWeight: "600",
                  textAlign: "left",
                  marginLeft: 10,
                  marginTop: 15,
                }}
              >
                Exterior Cleaning
              </div>
              <div className={styleSheet.restoreText}>
                Restore your car’s showroom shine with our meticulous exterior
                cleaning, covering wheels, windows, overall car body.
              </div>
              <img
                src={require("../assets/images/potrate/2.png")}
                className={styleSheet.potrateImage}
              />
              <img
                src={require("../assets/images/squaree/1.png")}
                className={styleSheet.potrateImage}
                style={{ marginTop: -100 }}
              />
              <Box sx={{ position: "relative", bottom: 350 }}>
                <div
                  style={{
                    color: "#41B4bb",
                    fontSize: 28,
                    fontWeight: "600",
                    textAlign: "left",
                    marginLeft: 10,
                    marginTop: 15,
                  }}
                >
                  Interior Cleaning
                </div>
                <p className={styleSheet.restoreText}>
                  Breathe new life into your car’s interior with durable special
                  cleaning. We tackle dust and dirt, leaving your car feeling
                  fresh and renewed.
                </p>
              </Box>
              <img
                src={require("../assets/images/squaree/2.png")}
                className={styleSheet.potrateImage}
                style={{ marginTop: -390 }}
              />
              <Box sx={{ position: "relative", bottom: 350 }}>
                <div
                  style={{
                    color: "#41B4bb",
                    fontSize: 28,
                    fontWeight: "600",
                    textAlign: "left",
                    marginLeft: 10,
                    marginTop: 15,
                  }}
                >
                  Add-On Services
                </div>
                <p className={styleSheet.restoreText}>
                  Enhance your car care routine with our premium add-ons, from
                  engine compartment wash to air vents cleaning, ensuring that
                  your car gets that extra touch of care.
                </p>
              </Box>
              <div className={styleSheet.itsYourCarText}>IT'S YOUR CAR,</div>
              <div className={styleSheet.itsYourCar2Text}>
                THODA TO KARO PYAAR
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  color: "#fff",
                  marginRight: 50,
                }}
              >
                Revisit Your Approach to Daily Car Care.
              </div>
              <Box className={classes.rowCenter}>
                <img
                  src={require("../assets/images/potrate/3.png")}
                  className={styleSheet.carImagesCss}
                />
                <Box
                  sx={{ alignItems: "center", marginRight: 1, width: "50%" }}
                >
                  <div className={styleSheet.baltiKapdaServiceText}>
                    BALTI KAPDA SERVICE
                  </div>
                  <div className={[classes.baltiKapdaServiceViewtWithBorder]}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Dirty Tyres
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Scratches
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Coat Marks
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Water Spots
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Residual Dust
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Bird Droppings
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Uneven Cleaning
                    </div>
                  </div>
                </Box>
              </Box>
              <Box className={classes.rowCenter} style={{ marginBottom: 20 }}>
                <Box sx={{ alignItems: "center", width: "45%" }}>
                  <div className={styleSheet.baltiKapdaServiceText}>
                    DOABLE PRO CAR CARE
                  </div>
                  <div className={[classes.baltiKapdaServiceViewtWithBorder]}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Clean Tyres
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Glossy Finish
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Dirt-free Fenders
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Flawless Cleaning
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Spotless Windows
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceView}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Damage Prevention
                    </div>
                  </div>
                  <div className={classes.baltiKapdaServiceViewtWithBorder}>
                    <div className={styleSheet.baltiKapdaServiceText}>
                      Protective Shield Against Dirt
                    </div>
                  </div>
                </Box>
                <img
                  src={require("../assets/images/potrate/4.png")}
                  className={styleSheet.carImagesCss}
                />
              </Box>
              {/*<--------------- DIFFERENCES----------------------> */}
              <div
                className={styleSheet.textCss}
                style={{ textAlign: "center" }}
              >
                DIFFERENCES
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 80px",
                  marginLeft: 10,
                  marginTop: "50px",

                  "@media (max-width:899px)": {
                    margin: "0 20px",
                    marginTop: "20px",
                  },
                }}
              >
                <div className={styleSheet.kapdaBaltiText}>BALTI-KAPDA</div>
                <div className={styleSheet.kapdaBaltiText}>
                  DOABLE PRO CAR CARE
                </div>
              </Box>
              <img
                src={require("../assets/images/kapdaAndBaltiImage.png")}
                className={styleSheet.kapdaAndBaltiImage}
              />
{/* bskbksjb */}
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    color: "#fff",
                    width: "32%",
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styleSheet.threeCalumText}>
                    -Marks and Scratches from rough cloths & dirty overused
                    water
                  </div>
                  <div className={styleSheet.threeCalumText}>
                    -Unclean water cleaning with ragged clothes
                  </div>
                  <div className={styleSheet.threeCalumText}>
                    -Uneven cleaning and residual dirt on overall car body
                  </div>
                  <div className={styleSheet.threeCalumText}>
                    -Neglected Tyre & Fender Cleaning
                  </div>
                  <div className={styleSheet.threeCalumText}>
                    -Color Fading and dullness due to usage of harsh water and
                    ineffective household detergents
                  </div>
                  <div className={styleSheet.threeCalumText}>
                    -Irregular and Mindless Cleaning
                  </div>
                </Box>
                <Box
                  sx={{
                    color: "#fff",
                    width: "32%",
                    borderLeft: "1px solid #009796",
                    borderRight: "1px solid #009796",
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styleSheet.threeMiddalText}>
                    Gentle Damage Control
                  </div>
                  <div className={styleSheet.threeMiddalText}>
                    Dedicated Equipment & Quality Products
                  </div>
                  <div className={styleSheet.threeMiddalText}>
                    Precise Cleaning
                  </div>
                  <div className={styleSheet.threeMiddalText}>Tidy Tyres</div>
                  <div className={styleSheet.threeMiddalText}>
                    Car Body Protection & Renewed Shine
                  </div>
                  <div className={styleSheet.threeMiddalText}>Daily Ease</div>
                </Box>
                <Box
                  sx={{
                    color: "#fff",
                    width: "33%",
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styleSheet.threeCalumTextRight}>
                    -Advanced cleaning process for a smooth finish preventing
                    damages
                  </div>
                  <div className={styleSheet.threeCalumTextRight}>
                    -High quality equipment & products used for each specific
                    cleaning step
                  </div>
                  <div className={styleSheet.threeCalumTextRight}>
                    -Thorough Car Cleaning, Even in Tight Spaces
                  </div>
                  <div className={styleSheet.threeCalumTextRight}>
                    -Daily specialized Tyre & Fender Cleaning for a Fresh Look
                  </div>
                  <div className={styleSheet.threeCalumTextRight}>
                    -Advanced pH-Balanced Wax Shampoo for protection from
                    Unwanted Damages
                  </div>
                  <div className={styleSheet.threeCalumTextRight}>
                    -Professional, At-Home & Hassle-Free Daily Car Care
                  </div>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div className={styleSheet.downlaodText}>
                      Download{" "}
                      <span
                        className={styleSheet.downlaodText}
                        style={{ color: "#328589", padding: 0, margin: 0 }}
                      >
                        DOABLE app
                      </span>
                    </div>
                    <img
                      src={require("../assets/images/iconss/rightArrow.png")}
                      className={classes.imageStyle}
                    />
                    {/* </div> */}
                  </Box>
                  <div style={{ display: "flex" }}>
                    <img
                      src={require("../assets/images/squaree/appleStoreIcon.png")}
                      // style={{ width: "50%", height: 80, marginTop: 11 }}
                      className={styleSheet.playStoreIcon}
                    />
                    <img
                      src={require("../assets/images/squaree/googlePlayIcon.jpeg")}
                      className={styleSheet.appStoreIcon}
                    />
                  </div>
                </Box>

                <img
                  src={require("../assets/images/squaree/3.png")}
                  style={{ width: "40%" }}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "#328589",
                alignSelf: "center",
                marginTop: 15,
                textTransform: "capitalize",
                margin: "2rem 0",
              }}
              onClick={handleButton}
              className={"card-hover"}
            >
              View Plans
              
            </Button>
            <Toaster
              toastOptions={{
                style: {
                  border: "1px solid #000",
                },
                className: "toast-width",
              }}
            />
          </Container>
        </Common>
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: "100%",
          padding: "0 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slide in={openModal}>
          <Box className={classes.locationModal}>
            <IconButton
              style={{
                color: "#328589",
                marginLeft: "92%",
                marginTop: -40,
              }}
              onClick={() => setOpenModal(false)}
            >
              <HighlightOffIcon />
            </IconButton>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center", fontWeight: "600", marginTop: -10 }}
            >
              Select Your Car
            </Typography>
            <p
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Please select your car make & model{" "}
            </p>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="demo-simple-select-label">
                Select Car Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={carBrand}
                label="Select Car Brand"
                onChange={(e) => {
                  // handleSector(e);
                  setCarBrand(e.target.value);
                  // console.log('value---> ', e.target.value);
                  handleCarModel(e.target.value);
                }}
              >
                {carBrandData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="demo-simple-select-label2">
                Select Car Model
              </InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select"
                value={carModel}
                label="Select Car Model"
                onChange={(e) => setCarModel(e.target.value)}
                displayEmpty={false}
              >
                {carModelData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "#328589",
                marginLeft: "44%",
                marginTop: 15,
              }}
              onClick={handleCarSubmit}
            >
              Done
            </Button>
          </Box>
        </Slide>
      </Modal>
    </React.Fragment>
  );
};
export default CarCleaningHome;
