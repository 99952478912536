import { makeStyles } from "@mui/styles";
import Globals from "../global/Globals";
import { Rotate90DegreesCcw } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  cardStyle: {
    height: "18vh",
    width: "22vh",
    borderRadius: 10,
    boxShadow: "0 1px 1px 1px #ccc",

    "@media (max-width: 899px)": {
      height: "12vh",
      width: "16vh",
      borderRadius: 10,
      boxShadow: "0 1px 1px 1px #ccc",
      marginTop: 18,
    },
  },
  cardText: {
    fontSize: 16,
    color: "#000",
    fontWeight: "500",
    padding: "0.5rem 0",
  },
  locationModal: {
    transform: "translate(-50%, -50%)",
    width: "25rem",
    height: "20rem",
    boxShadow: 20,
    padding: "2rem",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingInline: "15px 15px",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  },
  imageCss: {
    maxWidth: "70%",
    height: "auto",
    padding: 0,
    margin: 0,
    "@media (max-width: 899px)": {
      maxWidth: "60%",
      height: "auto",
      padding: 0,
      margin: 0,
      marginTop: 5,
    },
  },
  homePageText: {
    color: Globals.COLOR.PrimaryColor,
    fontSize: 18,
    fontWeight: "600",
  },
  homePageRedText: {
    color: "#FF2941",
    fontSize: 18,
    fontWeight: "600",
  },
  baltiKapdaServiceViewtWithBorder: {
    border: "1px solid #41B4bb",
    borderRadius: 20,
    alignSelf: "center",
    marginTop: 20,
    display: "inline-block",
    justifyContent: "center",
    padding: "1px 10px",

    "@media (max-width: 899px)": {
      padding: "1px 6px",
      marginTop: 10,
    },
  },
  baltiKapdaServiceView: {
    borderRadius: 20,
    alignSelf: "center",
    marginTop: 20,
    justifyContent: "center",

    "@media (max-width: 899px)": {
      marginTop: 10,
    },
  },
  baltiKapdaServiceText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "600",
  },
  rowCenter: {
    display: "flex",
    marginTop: 11,
    alignItems: "center",
    justifyContent: "space-between",
  },

  imageStyle: {
    filter:
      "invert(23%) sepia(34%) saturate(1079%) hue-rotate(140deg) brightness(90%) contrast(85%)",
    width: "30px",
    height: "30px",
    marginTop: 5,
    marginLeft: 10,

    "@media (max-width:899px)": {
      width: "15px",
      height: "15px",
    },
  },
});

const footerStyle = makeStyles(() => ({
  helpSupport: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Default style for larger screens

    "@media (max-width: 899px)": {
      alignItems: "start",
      justifyContent: "start", // Override for extra small screens
      padding: "0 1rem",
    },
  },
}));

const textStyle = makeStyles(() => ({
  fontSizeCss: {
    fontSize: 14,
    color: "#000",
    textAlign: "start",
  },
}));

const handleLocation = makeStyles(() => ({
  hideLocation: {
    fontSize: 16,
    color: "#fff",
    alignSelf: "flex-start",
    margin: 0,

    "@media (max-width: 899px)": {
      alignItems: "start",
      justifyContent: "start", // Override for extra small screens
      padding: "0 1rem",
    },
  },
}));
const CarCleaningHomeText = makeStyles(() => ({
  threeCalumText: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "500",
    textAlign: "left",
    marginLeft: 20,
    marginBottom: 20,
    padding: "10px 0",
    minHeight: "80px",

    "@media (max-width: 899px)": {
      fontSize: 12,
      fontWeight: "300",
      color: "#fff",
      textAlign: "left",
      marginLeft: 10,
      marginBottom: 20,
      padding: "10px 0",
      minHeight: "80px",
    },
  },
  threeMiddalText: {
    color: "#009796",
    fontSize: 15,
    fontWeight: "500",
    textAlign: "center",
    marginBottom: 20,
    padding: "10px 0",
    minHeight: "80px",

    "@media (max-width: 899px)": {
      fontSize: 12,
      fontWeight: "300",
      color: "#009796",
      textAlign: "center",
      marginBottom: 20,
      padding: "10px 0",
      minHeight: "80px",
    },
  },

  threeCalumTextRight: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "500",
    textAlign: "right",
    marginBottom: 20,
    padding: "10px 0",
    minHeight: "80px",

    "@media (max-width: 899px)": {
      color: "#fff",
      fontSize: 12,
      fontWeight: "300",
      textAlign: "right",
      marginBottom: 20,
      padding: "10px 0",
      minHeight: "80px",
    },
  },
  baltiKapdaServiceText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "600",

    "@media (max-width: 899px)": {
      color: "#fff",
      fontSize: 10,
      fontWeight: "300",
    },
  },
  CarCleaningHomeContainer: {
    backgroundColor: "#000",
    flex: 1,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: "5rem",
    width: "80%",
    position: "relative",
    alignItems: "center",

    "@media (max-width: 899px)": {
      width: "95%",
    },
  },
  potrateImage: {
    height: "60rem",
    width: "100%",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,

    "@media (max-width: 899px)": {
      height: "40rem",
      padding: "0 0 10px 0",
    },
  },

  letWantImage: {
    height: "12rem",
    width: "100%",
    marginTop: -60,
    "@media (max-width: 899px)": {
      height: "6rem",
    },
  },
  icons: {
    "@media (max-width: 899px)": {
      height: "6rem",
      width: "6rem",
    },
  },
  textCss: {
    color: "#41B4bb",
    fontSize: 35,
    fontWeight: "bold",
    marginTop: 20,
    "@media (max-width: 899px)": {
      fontSize: 22,
    },
  },
  itsYourCarText: {
    color: "#41B4bb",
    fontSize: 35,
    fontWeight: "bold",
    marginTop: 20,
    marginLeft: 130,
    marginTop: -220,
    textAlign: "left",
    "@media (max-width: 899px)": {
      fontSize: 22,
      marginLeft: 15,
      marginTop: -300,
    },
  },
  itsYourCar2Text: {
    color: "#41B4bb",
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "right",
    marginRight: 100,
    marginTop: -10,
    "@media (max-width: 899px)": {
      fontSize: 22,
      marginRight: 50,
    },
  },

  iconText: {
    color: "#fff",
    "@media (max-width:899px)": {
      // width: "40%",
      textAlign: "center",
      margin: "0 10px",
    },
  },
  restoreText: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "600",
    textAlign: "left",
    width: "95%",
    marginLeft: 10,

    "@media (max-width:899px)": {
      fontSize: 12,
      fontWeight: "400",
      textAlign: "left",
      width: "95%",
      marginLeft: 10,
    },
  },
  carImagesCss: {
    height: "35rem",
    width: "55%",

    "@media (max-width: 899px)": {
      height: "16rem",
      width: "50%",
    },
  },

  kapdaBaltiText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "600",
    textAlign: "center",

    "@media (max-width:899px)": {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "500",
      textAlign: "center",
    },
  },
  downlaodText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "600",
    textAlign: "center",
    marginLeft: 20,

    "@media (max-width:899px)": {
      textAlign: "center",
      fontSize: 10,
      fontWeight: "500",
      // textAlign: "center",
    },
  },

  kapdaAndBaltiImage: {
    width: "90%",
    height: "22rem",
    marginLeft: -50,

    "@media (max-width:899px)": {
      width: "99%",
      height: "10rem",
      marginLeft: -10,
    },
  },

  playStoreIcon: {
    width: "45%",
    height: 80,
    marginTop: 8,

    "@media (max-width:899px)": {
      width: "50%",
      height: 45,
    },
  },
  appStoreIcon: {
    width: "45%",
    height: 100,
    marginLeft: 10,
    "@media (max-width:899px)": {
      height: 60,
      width: "50%",
    },
  },
}));
const CarCleaningService = makeStyles(() => ({
  dropdown: {
    width: "40%",
    marginTop: "1rem",

    "@media (max-width: 899px)": {
      width: "70%",
      marginTop: "1rem",
    },
  },
}));

export {
  useStyles,
  footerStyle,
  textStyle,
  CarCleaningHomeText,
  CarCleaningService,
};
