import React, { useEffect, useState } from "react";
// import privacypolicy from "../assets/pdf/privacypolicy.pdf";
import Common from "./Common";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Globals from "../global/Globals";
import axios from "axios";
import Arrow from "@mui/icons-material/ArrowForward";
import toast, { Toaster } from "react-hot-toast";

const HelpAndSupport = () => {
  const BASE_URL = Globals.BASE_URL;
  const [servicesData, setServicesData] = useState([]);
  const [service, setService] = useState(null);
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_URL}/api-getHelpSupportService.html`,
    };

    axios(config)
      .then((response) => {
        var count = Object.keys(response.data.data).length;
        let ary = [];
        for (var i = 0; i < count; i++) {
          ary.push({
            value: response.data.data[i].title,
            label: response.data.data[i].title,
          });
        }
        setServicesData(ary);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sendDataToServer = async () => {
    if (name.length === 0 || mobile.length === 0) {
      toast.error("Please enter your Name and Mobile number!!");
    } else if (mobile.length !== 10) {
      toast.error("Please enter valid mobile number");
    } else if (service == null || comment == null) {
      toast.error("All Fields are required!!");
    } else if (checked === false) {
      toast.error("Please accept terms & conditions!!");
    } else {
      // let userid = await AsyncStorage.getItem('userid');
      var url = `${BASE_URL}/api-storeHelpSupport.html`;
      const dataSend = {
        userid: 0,
        service: service,
        message: comment,
      };
      axios
        .post(url, JSON.stringify(dataSend))
        .then((response) => {
          console.log("response---->", response);
        })
        .catch((error) => {
          console.log("Error-------->", error);
        });
      toast.success(
        "Thank you for submitting your request! We will get back to you soon."
      );
      setService("");
      setComment("");
      setName("");
      setMobile("");
      setChecked(false);
    }
  };
  return (
    <>
      <Common>
        <h2>Help And Support</h2>
        <p style={{ fontSize: 16, color: "#575757", margin: 0 }}>
          Please submit your concern so that we can reach out to you
        </p>
        <Container>
          <Grid
            container
            columns={12}
            style={{
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              padding: "0.5rem",
              marginTop: "3rem",
            }}
            sx={{ backgroundColor: "#095659" }}
          >
            <Grid
              item
              xs={6}
              sx={{ color: "#FFF", textAlign: "start", marginLeft: "0.5rem" }}
            >
              Help us to understand your need
            </Grid>
          </Grid>
          <Box
            sx={{
              shadowOpacity: 0.5,
              shadowRadius: 2,
              boxShadow: 2,
              borderBottomRightRadius: "1rem",
              borderBottomLeftRadius: "1rem",
              backgroundColor: "#FDFDFD",
              padding: "1.5rem",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#000",
                textAlign: "start",
                fontSize: 16,
                margin: 0,
              }}
            >
              Please fill in the required additional information
            </p>

            <Box style={{ width: "100%", marginTop: "1.2rem" }}>
              <TextField
                placeholder="Name"
                sx={{ marginRight: "2rem", width: "30%" }}
                required={true}
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
              <TextField
                placeholder="Mobile Number"
                sx={{ width: "30%" }}
                required
                onChange={(event) => setMobile(event.target.value)}
                value={mobile}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Box>

            <FormControl fullWidth style={{ marginTop: 10, width: "30%" }}>
              <InputLabel id="demo-simple-select-label">Service</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={service}
                label="Service"
                key={service}
                onChange={(e) => {
                  setService(e.target.value);
                }}
              >
                {servicesData.map((item) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              placeholder="Any Comments"
              multiline
              sx={{ width: "60%", padding: "1rem 0" }}
              minRows={10}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            ></TextField>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: "2rem 0",
            }}
          >
            <Box
              sx={{ width: "60%", alignSelf: "center", textAlign: "center" }}
            >
              We are trying our best to make all the services available at most
              of the location. Thank you for submitting the request, we will get
              back to you soon.
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Checkbox
              checked={checked}
              size="medium"
              onClick={() => setChecked(!checked)}
            />
            <h4>I agree to the Terms & conditions</h4>
          </Box>
          <Button
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#328589",
              alignSelf: "center",
              textTransform: "capitalize",
              margin: "2rem 0",
            }}
            onClick={sendDataToServer}
          >
            Sumbit Request
            <Icon
              color="#fff"
              sx={{ marginTop: -1, height: 25, width: 30 }}
              size="small"
            >
              <Arrow />
            </Icon>
          </Button>
          <Toaster
            toastOptions={{
              style: {
                border: "1px solid #000",
              },
              className: "toast-width",
            }}
          />
        </Container>
      </Common>
    </>
  );
};
export default HelpAndSupport;
